import React from "react";
import image from "../../assets/images/subheader.jpg";
import CommonBreadcrumbs from "../../common/CommonBreadcrumbs/CommonBreadcrumbs";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import image1 from "../../assets/images/rajadhiraj.jpg";
import image2 from "../../assets/images/B.Swami_1.jpg";
import image3 from "../../assets/images/T.Swami_2.jpg";
import image4 from "../../assets/images/M.Swami_3.jpg";
import image5 from "../../assets/images/H.Swami_4.jpg";
import image6 from "../../assets/images/G.Swami_5.jpg";
import image7 from "../../assets/images/V.Swami_6.jpg";
import image8 from "../../assets/images/Guruji_7.jpg";
import "./GuruTradition.css";

const GuruTradition = () => {
  const breadcrumbsData = [
    { label: "Home", url: "/" },
    { label: "About Us", url: "/about-us" },
    <label color="text.primary" className="active-link-color">
      BRAHMANAND TRADITION
    </label>,
  ];
  return (
    <>
      <div className="contact-img-wrap">
        <img src={image} alt="" className="about-img" />
        <div className="breadcrumbs-wrap">
          <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
        </div>
      </div>
      <div className="section-padding"></div>
      <Container>
        {/* <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={3}>
            <img src={image1} alt="image" className="tradition-image" />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <img src={image2} alt="image" className="tradition-image" />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <img src={image3} alt="image" className="tradition-image" />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <img src={image4} alt="image" className="tradition-image" />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <img src={image5} alt="image" className="tradition-image" />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <img src={image6} alt="image" className="tradition-image" />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <img src={image7} alt="image" className="tradition-image" />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <img src={image8} alt="image" className="tradition-image" />
          </Grid>
        </Grid> */}
        <Grid
          container
          spacing={3}
          justifyContent="center"
          className="sub-activities"
        >
          <Grid item xs={12} sm={6} md={3}>
            <div className="tradition-image-wrap">
              <img src={image1} alt="" className="tradition-image" />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="tradition-image-wrap">
              <img src={image2} alt="" className="tradition-image" />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="tradition-image-wrap">
              <img src={image3} alt="" className="tradition-image" />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="tradition-image-wrap">
              <img src={image4} alt="" className="tradition-image" />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="tradition-image-wrap">
              <img src={image5} alt="" className="tradition-image" />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="tradition-image-wrap">
              <img src={image6} alt="" className="tradition-image" />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="tradition-image-wrap">
              <img src={image7} alt="" className="tradition-image" />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="tradition-image-wrap">
              <img src={image8} alt="" className="tradition-image" />
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default GuruTradition;
