import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import { IoCheckmarkSharp } from "react-icons/io5";
import SectionTitle from "../../common/SectionTitle/SectionTitle";
import Loader from "../../common/Loader/Loader";
import { fetchliveVideo } from "../../api/API";
import "./Videos.css";
import YouTubeLiveStatusChecker from "../../common/Youtube/YoutubeStreamer";

const Videos = ({ setIsVideoLoaded }) => {
  const [apiData, setApiData] = useState(null);
  const [liveVideoTitle, setLiveVideoTitle] = useState([]);
  const [liveVideoDescription, setLiveVideoDescription] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  // Convert YouTube URL to embedded iframe
  const getYoutubeEmbedUrl = (url) => {
    if (url) {
      // Regular expression to match YouTube URL patterns
      const youtubeRegex =
        /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s?]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})$/;

      const match = url.match(youtubeRegex);

      if (match && match[1]) {
        const videoId = match[1];
        return `https://www.youtube.com/embed/${videoId}`;
      }
    }
    return null;
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetchliveVideo({
        url: process.env.REACT_APP_API_URL,
        page: "home",
      });
      if (response.data.status == true) {
        setIsVideoLoaded(true);

        setApiData(response.data);
        setLiveVideoTitle(response.data.responseBody.live_setting.title);
        setLiveVideoDescription(
          response.data.responseBody.live_setting.description
        );
        console.log("resposneLive", response.data);
        console.log(
          "responseVideoLive",
          apiData.responseBody.live_setting.points
        );
        console.log("resposneLive", response);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("apiData", apiData);
  }, [apiData]);

  // const youtubeVideoUrl = "https://www.youtube.com/embed/BefAlImecIQ";
  const apiKey = "AIzaSyDEPqey9i1sZFnOGiiE1jqa4tLLH-VC4D8";
  const channelId = "UC1Zzxq60fquqYgOK9RugT1Q";

  const youtubeEmbedUrl = apiData
    ? getYoutubeEmbedUrl(apiData.responseBody.live_setting.youtube_link)
    : null;

  return (
    <>
      <div className="section-padding"></div>
      <Container>
        <div className="spinner-container">
          {loading && (
            <div className="spinner">
              <Loader />
            </div>
          )}
          <div className="video-content-wrap">
            <div className="video-inner-content-wrap">
              <Card style={{ padding: "15px", minHeight: "315px" }}>
                <YouTubeLiveStatusChecker
                  channelId={channelId}
                  apiKey={apiKey}
                  fallbackUrl={youtubeEmbedUrl}
                />
              </Card>
            </div>

            <div className="video-inner-content-wrap">
              <SectionTitle title={liveVideoTitle} data-aos="zoom-in" />
              <div className="video-inner-content">
                <div className="video-content">
                  {apiData &&
                    apiData.responseBody &&
                    apiData.responseBody.live_setting &&
                    apiData.responseBody.live_setting.points &&
                    apiData.responseBody.live_setting.points.map(
                      (point, index) => (
                        <div className="video-content-text" key={index}>
                          <div className="icon-wrap">
                            <IoCheckmarkSharp className="check-icon" />
                          </div>
                          <div>
                            <h5>{point}</h5>
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>
              {loading ? null : (
                <div
                  className="content"
                  data-aos="fade-left"
                  style={{ textAlign: "justify" }}
                  // data-aos-delay="1500"
                >
                  <p>{liveVideoDescription}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
      {/* {loading && <Loader />} */}
    </>
  );
};
export default Videos;

// import React, { useEffect, useState } from "react";
// import Container from "@mui/material/Container";
// import { IoCheckmarkSharp } from "react-icons/io5";
// import SectionTitle from "../../common/SectionTitle/SectionTitle";
// import Loader from "../../common/Loader/Loader";
// import { fetchliveVideo } from "../../api/API";
// import "./Videos.css";
// import YouTubeLiveStatusChecker from "../../common/Youtube/YoutubeStreamer";

// const Videos = ({ setIsVideoLoaded }) => {
//   const [apiData, setApiData] = useState(null);
//   const [liveVideoTitle, setLiveVideoTitle] = useState([]);
//   const [liveVideoDescription, setLiveVideoDescription] = useState([]);
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const getYoutubeEmbedUrl = (url) => {
//     if (url) {
//       // Regular expression to match YouTube URL patterns
//       const youtubeRegex =
//         /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s?]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})$/;

//       const match = url.match(youtubeRegex);

//       if (match && match[1]) {
//         const videoId = match[1];
//         return `https://www.youtube.com/embed/${videoId}`;
//       }
//     }
//     return null;
//   };

//   const fetchData = async () => {
//     setLoading(true);
//     try {
//       const response = await fetchliveVideo({
//         url: process.env.REACT_APP_API_URL,
//         page: "home",
//       });
//       if (response.data.status === true) {
//         setIsVideoLoaded(true);
//         setApiData(response.data);
//         setLiveVideoTitle(response.data.responseBody.live_setting.title);
//         setLiveVideoDescription(
//           response.data.responseBody.live_setting.description
//         );
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <>
//       <div className="section-padding"></div>
//       <Container>
//         <div className="spinner-container">
//           {loading && (
//             <div className="spinner">
//               <Loader />
//             </div>
//           )}
//           {apiData && !loading && (
//             <div className="video-content-wrap">
//               <div className="video-inner-content-wrap">
//                 <YouTubeLiveStatusChecker
//                   channelId={"your_channel_id"}
//                   apiKey={"your_api_key"}
//                   fallbackUrl={
//                     apiData.responseBody.live_setting.youtube_link
//                       ? getYoutubeEmbedUrl(
//                           apiData.responseBody.live_setting.youtube_link
//                         )
//                       : null
//                   }
//                 />
//               </div>
//               <div className="video-inner-content-wrap">
//                 <SectionTitle title={liveVideoTitle} data-aos="zoom-in" />
//                 <div className="video-inner-content">
//                   <div className="video-content">
//                     {apiData.responseBody &&
//                       apiData.responseBody.live_setting &&
//                       apiData.responseBody.live_setting.points &&
//                       apiData.responseBody.live_setting.points.map(
//                         (point, index) => (
//                           <div className="video-content-text" key={index}>
//                             <div className="icon-wrap">
//                               <IoCheckmarkSharp className="check-icon" />
//                             </div>
//                             <div>
//                               <h5>{point}</h5>
//                             </div>
//                           </div>
//                         )
//                       )}
//                   </div>
//                 </div>
//                 {liveVideoDescription && (
//                   <div className="content" data-aos="fade-left">
//                     <p>{liveVideoDescription}</p>
//                   </div>
//                 )}
//               </div>
//             </div>
//           )}
//         </div>
//       </Container>
//     </>
//   );
// };

// export default Videos;
