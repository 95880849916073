import React, { useEffect, useState } from "react";
import InnerpageLoader from "../Home/InnerpageLoader";
import { customDetailPage, fetchSlider } from "../../api/API";
import CommonBreadcrumbs from "../../common/CommonBreadcrumbs/CommonBreadcrumbs";
import { useParams } from "react-router-dom";
import { Card, Container, Grid } from "@mui/material";
import "../CustomePage/CustomPage.css";

const CustomPage = () => {
  const [CustomPageData, setCustomPageData] = useState({});
  const [banner, setBanner] = useState(null);
  const param = useParams();
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const breadcrumbsData = [
    { label: "Home", url: "/" },
    <label className="active-link-color">{param.name}</label>,
  ];

  useEffect(() => {
    handleCustomPage();
  }, []);

  const handleCustomPage = async () => {
    const params = {
      url: process.env.REACT_APP_API_URL,
      page: "custom",
      custom_id: param.id,
    };
    try {
      const resp = await customDetailPage(params);
      if (resp.data.code == 200 || resp.data.code == 201) {
        setCustomPageData(resp.data.responseBody);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };
  useEffect(() => {
    fetchBanner();
  }, []);
  const fetchBanner = async () => {
    try {
      const response = await fetchSlider({
        url: process.env.REACT_APP_API_URL,
        page: "custom",
        custom_id: param.id,
      });
      setBanner(response.data.responseBody);
      console.log("responsebanner", response.data.responseBody);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      {/* <div className="contact-img-wrap">
        <div className="spinner-container-banner">
          <>
            <img
              src={require("../../assets/images/slider_1.webp")}
              alt=""
              style={{ height: "252px", width: "100%" }}
            />
          </>
        </div>
        <div className="breadcrumbs-wrap">
          <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
        </div>
      </div> */}
      <div className="contact-img-wrap">
        <div className="spinner-container-banner">
          <InnerpageLoader
            src={banner}
            className="about-img"
            onImageLoad={handleImageLoad}
          />
        </div>
        {imageLoaded && (
          <div className="breadcrumbs-wrap">
            <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
          </div>
        )}
      </div>
      <Container className="custom-page-content">
        <div className="container about-container about-us-content custom-page-div">
          <h4>{CustomPageData ? CustomPageData.name : "-"}</h4>
          <img
            src={CustomPageData.image ? CustomPageData.image : ""}
            className="custom-image"
          />
          <p
            className="mt-sm-3 mt-md-3 mt-lg-3 custom-page-paragraph"
            dangerouslySetInnerHTML={{
              __html: CustomPageData.long_description,
            }}
          ></p>
          <p className="mt-4"></p>
        </div>
      </Container>
    </>
  );
};

export default CustomPage;
