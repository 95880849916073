import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { fetchSantPhotos } from "../../api/API";
import Loader from "../../common/Loader/Loader";
import "./SantPhotos.css";
import { useNavigate } from "react-router-dom";

function SantPhotos() {
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetchSantPhotos({
          url: process.env.REACT_APP_API_URL,
          page: "home",
        });
        if (response.data.status == true) {
          setApiData(response?.data?.responseBody);
          console.log("responseSantPhotos", response?.data?.responseBody);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(true);
      }
    };

    fetchData();
  }, []);

  const navigate = useNavigate();

  const handleClick = (item) => {
    console.log("item",item)
    navigate(`/about-us/about/${item.details.title}/${item.id}`);
  };

  return (
    <>
      <div className="section-padding"></div>
      <div className="sant-bg-image">
        <Container className="container">
          <div className="spinner-container">
            {loading && (
              <div className="spinner">
                <Loader />
              </div>
            )}
            <Grid container spacing={2}>
              {apiData &&
                apiData.map((item, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4} lg={4}>
                    <div
                      className="sant-photo-content pt-5 pt-sm-0"
                      data-aos="zoom-in"
                      onClick={() => handleClick(item)}
                    >
                      <img
                        src={item.details.image}
                        alt={item.details.title}
                        className="sant-img"
                      />
                      <h4>{item.details.title}</h4>
                      <p
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></p>
                    </div>
                  </Grid>
                ))}
            </Grid>
          </div>
        </Container>
      </div>
    </>
  );
}

export default SantPhotos;
