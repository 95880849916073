import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

const AudioAlbumList = ({ albumnData }) => {
  const navigate = useNavigate();
  console.log("albumdata", albumnData);

  const handleClick = (album_id, publication_id) => {
    console.log("Clicked on Album with album id:", album_id);
    navigate(`/audio-album/${album_id}/publication/${publication_id}`);
  };

  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      className="katha-content-wrap"
    >
      {albumnData &&
        albumnData.map((album, index) => (
          <Grid key={index} item xs={12} sm={4} md={3}>
            <div className="katha-img-wrap">
              <img src={album.image} alt="" className="katha-img" />
              <div className="katha-content">
                <div>
                  <h4>{album.name ? album.name : album.title}</h4>
                </div>
                <div>
                  <p>{album.short_description}</p>
                </div>
                <div>
                  <button
                    onClick={() => handleClick(album.id, album.publication_id)}
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          </Grid>
        ))}
    </Grid>
  );
};

export default AudioAlbumList;
