import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import image1 from "../../assets/images/slider_1.webp";
import image2 from "../../assets/images/slider_2.webp";
import image3 from "../../assets/images/slider_3.webp";
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
import "./Home.css";
import Videos from "../Videos/Videos";
import Publication from "../Publication/Publication";
import Activities from "../Activities/Activities";
import SantPhotos from "../SantPhotos/SantPhotos";
import Loader from "../../common/Loader/Loader";
import { fetchSlider } from "../../api/API";
import MyImage from "./NewHome";
import HomeSlider from "./HomeSlider";

const Home = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isPublicationLoaded, setIsPublicationLoaded] = useState(false);
  const [isActivityLoaded, setIsActivityLoaded] = useState(false);
  const [isSliderLoaded, setIsSliderLoaded] = useState(false);

  return (
    <div className="container-fluid slider-wrap">
      <div className="spinner-container">
        <HomeSlider />

        <Videos setIsVideoLoaded={setIsVideoLoaded} />

        <Publication setIsPublicationLoaded={setIsPublicationLoaded} />

        <Activities setIsActivityLoaded={setIsActivityLoaded} />

        <SantPhotos />
      </div>
    </div>
  );
};

export default Home;
