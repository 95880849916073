import React from "react";
import ReactDOM from "react-dom";
import Favicon from "react-favicon";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
// import reportWebVitals from "./reportWebVitals";
import { store } from "./Redux/store";
import faviconUrl from "../src/assets/images/SSGD-logo.jpeg";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

ReactDOM.render(
  <Provider store={store}>
    <Favicon url={faviconUrl} />
    <App />
  </Provider>,
  document.getElementById("root")
);
