import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import CommonBreadcrumbs from "../../../common/CommonBreadcrumbs/CommonBreadcrumbs";
import Wallpaper from "../Wallpaper/Wallpaper";
import Tablist from "../Tablist/Tablist";
import "./PublicationDetails.css";
import { useNavigate, useParams } from "react-router-dom";
import { fetchPublicationList, fetchSlider } from "../../../api/API";
import Images from "../../../commonPublication/Images";
import VideoAndAlbumList from "../VideoNew/VideoAndAlbumList";
import AudioAndAlbumList from "../AudioNew/AudioAndAlbumList";
import Loader from "../../../common/Loader/Loader";
import BookAndAlbumList from "../../../commonPublication/BookAndAlbumList";
import InnerpageLoader from "../../Home/InnerpageLoader";
import PublicationSearchModal from "../../../common/PublicationSearchModal/PublicationSearchModal";

const PublicationDetails = () => {
  const breadcrumbsData = [
    { label: "Home", url: "/" },
    <label color="text.primary" className="active-link-color">
      Publication
    </label>,
  ];

  const [SelectedPublicationslug, setSelectedPublicationslugn] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [year, setYear] = React.useState("");
  const [indicatorPosition, setIndicatorPosition] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [publicationList, setPublicationList] = useState([]);
  const [activeStatus, setActiveStatus] = useState(false);
  const [publicationData, setPublicationData] = useState(null);
  const [publicatioAlbumnData, setPublicationAlbumData] = useState("");
  const [isAlbum, setIsAlbum] = useState(false);
  const [list, setList] = useState([]);
  const [publicationId, setPublicationId] = useState();
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const params = useParams();
  useEffect(() => {
    console.log("paramssssssss===>", params.tab, params.id);
    fetchData(params.id);
    setPublicationId(params.id);
  }, [params.id]);

  const navigate = useNavigate();
  useEffect(() => {}, [params]);

  const handleButtonClick = (
    publicationName,
    PublicationId,
    index,
    ui_slug,
    publicationData,
    isActive
  ) => {
    setPublicationAlbumData(null);
    console.log("Publicationiddddd", PublicationId);
    console.log("publicationData....nik", publicationData);

    setSelectedPublicationslugn(ui_slug);
    setActiveTab(index);
    setIndicatorPosition(index);
    fetchData(PublicationId);
    setActiveStatus(isActive);

    window.history.pushState(
      null,
      "",
      `/publication-detail/${PublicationId}/${publicationName}`
    );
    setPublicationId(PublicationId);

    // navigate(`/publication-detail/${PublicationId}/${publicationName}`);
    setPublicationId(PublicationId);
    console.log("publicationId123", publicationId);
    console.log("ui_slugui_slugui_slug", ui_slug);
    console.log("publicationDatapublicationData", publicationData);
  };

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  const updateDataByApi = (id) => {
    setPublicationId(id);
    fetchData(id);
  };

  console.log("responseeALbum_______.......", publicatioAlbumnData);
  const fetchData = async (publicationid) => {
    try {
      const response = await fetchPublicationList({
        url: process.env.REACT_APP_API_URL,
        page: "publication",
        // order_by: "all",
        publication_id: publicationid,
        // publication_id: params.id,
      });

      if (response?.data?.responseBody.list_publication) {
        setPublicationData(response.data.responseBody.publication.ui_slug);
        // setSelectedPublication(response.data.responseBody.list_publication);
      }
      setSelectedPublicationslugn(
        response.data.responseBody.publication.ui_slug
      );

      if (response.data.responseBody.albums) {
        setIsAlbum(true);
        setPublicationAlbumData(response.data.responseBody.albums);
      }
      if (response.data.responseBody.list) {
        setIsAlbum(false);
        setPublicationAlbumData(response.data.responseBody.list);
        console.log("lislislistttt", response.data.responseBody.list);
      }
      setList(response.data.responseBody);

      setPublicationList(response?.data?.responseBody.list_publication);
      console.log("listname", response?.data?.responseBody.list_publication);
      setApiData(response);

      console.log("reponseresponse", response.data.responseBody.publication);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const response = await fetchSlider({
          url: process.env.REACT_APP_API_URL,
          page: "publication",
        });
        setBanner(response.data.responseBody);
        console.log("responsebanner", response.data.responseBody);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchBanner();
  }, []);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  // const openModal = () => {
  //   setModalOpen(true);
  // };

  // const closeModal = () => {
  //   setModalOpen(false);
  // };

  return (
    <>
      <div className="contact-img-wrap">
        <div className="spinner-container-banner">
          <InnerpageLoader
            src={banner}
            className="about-img"
            onImageLoad={handleImageLoad}
          />
        </div>
        {imageLoaded && (
          <div className="breadcrumbs-wrap">
            <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
          </div>
        )}
      </div>
      <Container>
        <div className="spinner-container">
          {loading && (
            <div className="spinner">
              <Loader />
            </div>
          )}
          {Array.isArray(publicationList) && publicationList.length > 0 ? (
            <div className="publication-serach-wrap">
              <div>
                <div className="publication-tabs">
                  <div className="publication-tab-wrap">
                    {publicationList.map((publication, index) => (
                      <div key={index}>
                        <button
                          className={
                            publicationId?.toString() ===
                            publication.id.toString()
                              ? "active-tab"
                              : ""
                          }
                          onClick={() =>
                            handleButtonClick(
                              publication.name,
                              publication.id,
                              index,
                              publication.ui_slug,
                              publication.albums
                            )
                          }
                        >
                          {publication.name}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <PublicationSearchModal onTap={updateDataByApi} />
            </div>
          ) : (
            <div className="no-data">No data available</div>
          )}
        </div>
      </Container>

      <Container className="pt-2">
        {SelectedPublicationslug === "image" && (
          <Images publicatioAlbumnData={publicatioAlbumnData} />
        )}
        {SelectedPublicationslug === "pdf" && (
          <BookAndAlbumList
            publicatioAlbumnData={publicatioAlbumnData}
            isAlbum={isAlbum}
          />
        )}
        {SelectedPublicationslug === "audio" && (
          <AudioAndAlbumList
            albumnData={publicatioAlbumnData}
            isAlbum={isAlbum}
          />
        )}
        {SelectedPublicationslug === "video" && (
          <VideoAndAlbumList
            albumData={publicatioAlbumnData}
            isAlbum={isAlbum}
          />
        )}
      </Container>
      <div className="section-padding"></div>
    </>
  );
};

export default PublicationDetails;
