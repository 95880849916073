import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
import "./Home.css";
import { fetchSlider } from "../../api/API";
import LazyLoad from "react-lazyload";

const HomeSlider = () => {
  const CustomPrevArrow = (props) => (
    <div {...props} className="custom-prev-arrow">
      <GrPrevious className="custom-prev-icon" />
    </div>
  );

  const CustomNextvArrow = (props) => (
    <div {...props} className="custom-next-arrow">
      <GrNext className="custom-next-icon" />
    </div>
  );

  const [sliderSettings, setSliderSettings] = useState({
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextvArrow />,
    // beforeChange: (current, next) => {
    //   console.log("Current slide index", current);
    //   console.log("Next slide index", next);
    // },
    // afterChange: (current) => {
    //   console.log("Current slide index after change", current);
    //   console.log("Current image URL after change", apiData[current]);
    // },
  });

  const [apiData, setApiData] = useState([]);
  const [failedImages, setFailedImages] = useState(new Set());

  const fetchData = async () => {
    try {
      const response = await fetchSlider({
        url: process.env.REACT_APP_API_URL,
        page: "home",
      });
      if (response.data.code === 200 || response.data.code === 201) {
        setApiData(response.data.responseBody);
        console.log("response.data.code", response.data.code);
      }
      console.log("responsehome", response.data.responseBody);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const handleImageError = (e) => {
  //   e.target.src =
  //     "https://www.google.com/imgres?q=images&imgurl=https%3A%2F%2Fcdn.pixabay.com%2Fphoto%2F2015%2F04%2F23%2F22%2F00%2Ftree-736885_1280.jpg&imgrefurl=https%3A%2F%2Fpixabay.com%2Fimages%2Fsearch%2Focean%2F&docid=Ih9znGUKkTWkvM&tbnid=aVgXecnmQ_f1MM&vet=12ahUKEwjH_rHgmp6GAxVdcmwGHRSvAx0QM3oECBUQAA..i&w=1280&h=797&hcb=2&ved=2ahUKEwjH_rHgmp6GAxVdcmwGHRSvAx0QM3oECBUQAA";
  // };

  //2
  // const handleImageError = (index) => {
  //   setFailedImages((prev) => new Set(prev).add(index));
  // };

  return (
    <div className="container-fluid slider-wrap">
      <div className="spinner-container">
        <Slider {...sliderSettings} className="slider-buttons">
          {apiData.map(
            (image, index) => (
              <div key={index}>
                <div>
                  <LazyLoad height={200} offset={100} once>
                    <img
                      src={image}
                      alt="Placeholder"
                      style={{ width: "100%", height: "auto" }}
                      data-src={image}
                      className="slider-img"
                      // onError={handleImageError}
                    />
                  </LazyLoad>
                </div>
              </div>
            )
            // 2
            // !failedImages.has(index) && (
            //   <div key={index}>
            //     <div>
            //       <LazyLoad height={200} offset={100} once>
            //         <img
            //           src={image}
            //           alt={`Slide ${index}`}
            //           style={{ width: "100%", height: "auto" }}
            //           onError={() => handleImageError(index)}
            //           className="slider-img"
            //         />
            //       </LazyLoad>
            //     </div>
            //   </div>
            // )
          )}
        </Slider>
      </div>
    </div>
  );
};

export default HomeSlider;
