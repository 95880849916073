import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CommonBreadcrumbs from "../common/CommonBreadcrumbs/CommonBreadcrumbs";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import PublicationSearchModal from "../common/PublicationSearchModal/PublicationSearchModal";
import InnerpageLoader from "../pages/Home/InnerpageLoader";
import {
  fetchPublicationDetails,
  fetchPublicationList,
  fetchSlider,
} from "../api/API";

const BookAlbumList = () => {
  const params = useParams();
  console.log("params.ididid", params);

  ///FOR BREADCRUM
  const [publicationDetailResponse, setPublicationDetailResponse] = useState();

  const breadcrumbsData = [
    { label: "Home", url: "/" },
    {
      label: `${publicationDetailResponse?.publication?.name}`,
      url: `/publication-detail/${publicationDetailResponse?.publication?.id}/${publicationDetailResponse?.publication?.name}`,
    },
    <label color="text.primary" className="active-link-color">
      {publicationDetailResponse?.albums?.title}
    </label>,
  ];

  const { id } = useParams();
  console.log("id.....", id);
  const navigate = useNavigate();
  const [bookDetailsList, setBookDetailsList] = useState([]);

  const [publicationList, setPublicationList] = useState([]);

  const [publicationId, setPublicationId] = useState();
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  // const handleViewDetailsClick = (id) => {
  //   navigate(`/publication/${params.id}/book-list/${id}`);
  //   console.log("");
  // };

  const handleView = (pdfName) => {
    const parts = pdfName.split("/");
    const fileNameWithExtension = parts[parts.length - 1];
    const fileName = fileNameWithExtension.split(".")[0];
    console.log("pdfname", fileName);
    localStorage.setItem(fileName, pdfName);

    navigate(`/pdf/${params.id}/${fileName}/publication/${params.id}`);
  };

  const handleButtonClick = (
    publicationName,
    PublicationId,
    index,
    ui_slug,
    publicationData,
    isActive
  ) => {
    fetchData1(PublicationId);

    setPublicationId(PublicationId);

    navigate(`/publication-detail/${PublicationId}/${publicationName}`);
    setPublicationId(PublicationId);
    console.log("publicationId123", publicationId);
    console.log("ui_slugui_slugui_slug", ui_slug);
    console.log("publicationDatapublicationData", publicationData);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchPublicationDetails({
          url: process.env.REACT_APP_API_URL,
          page: "publication",
          album_id: id,
        });
        setBookDetailsList(response.data.responseBody.list);
        setPublicationDetailResponse(response.data.responseBody);
        console.log(
          "responsePublicationDetails......",
          response.data.responseBody.list
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (params.pub_id) {
      fetchData1(params.pub_id);
      setPublicationId(params.pub_id);
    }
  }, [params.pub_id]);

  const fetchData1 = async (publicationid) => {
    try {
      const response = await fetchPublicationList({
        url: process.env.REACT_APP_API_URL,
        page: "publication",
        order_by: "all",
        publication_id: publicationid,
        // publication_id: params.id,
      });

      console.log(
        "publication------------",
        response.data.responseBody.publication.ui_slug
      );

      setPublicationList(response?.data?.responseBody.list_publication);
      console.log("listname", response?.data?.responseBody.list_publication);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchBanner = async () => {
      try {
        const response = await fetchSlider({
          url: process.env.REACT_APP_API_URL,
          page: "publication",
        });
        setBanner(response.data.responseBody);
        console.log("responsebannerBook", response.data.responseBody);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchBanner();
  }, []);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
      <div className="contact-img-wrap">
        <div>
          <div className="contact-img-wrap">
            <div className="spinner-container-banner">
              <InnerpageLoader
                src={banner}
                className="about-img"
                onImageLoad={handleImageLoad}
              />
            </div>
            {imageLoaded && (
              <div className="breadcrumbs-wrap">
                <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
              </div>
            )}
          </div>
        </div>
      </div>
      <Container>
        {Array.isArray(publicationList) && publicationList.length > 0 ? (
          <div className="publication-serach-wrap">
            <div>
              <div className="publication-tabs">
                <div className="publication-tab-wrap">
                  {publicationList.map((publication, index) => (
                    <div key={index}>
                      <button
                        className={
                          publicationId?.toString() ===
                          publication.id.toString()
                            ? "active-tab"
                            : ""
                        }
                        onClick={() =>
                          handleButtonClick(
                            publication.name,
                            publication.id,
                            index,
                            publication.ui_slug,
                            publication.albums
                          )
                        }
                      >
                        {publication.name}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <PublicationSearchModal />
          </div>
        ) : (
          <div></div>
        )}

        <Grid
          container
          spacing={3}
          justifyContent="center"
          className="katha-content-wrap"
        >
          {Array.isArray(bookDetailsList) &&
            bookDetailsList.map((book, index) => (
              <Grid item xs={6} sm={6} md={3} key={index}>
                <div className="katha-img-wrap">
                  <img src={book.image} alt="" className="katha-img" />
                  <div className="katha-content">
                    <div>
                      <h4>{book.name}</h4>
                    </div>
                    <div>
                      <p>{book.short_description}</p>
                    </div>
                    <div>
                      <button
                        onClick={() =>
                          handleView(
                            JSON.parse(book.media)[0],
                            book.name,
                            book.short_description
                          )
                        }
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
        </Grid>
      </Container>
    </>
  );
};

export default BookAlbumList;
