import React from "react";
import "./AudioPlayerLoader.css";

const AudioPlayerLoader = () => {
  return (
    <>
      <span class="audio-loader"></span>
    </>
  );
};

export default AudioPlayerLoader;
