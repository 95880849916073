import React from "react";
import notFound from "../../assets/images/not-found-img.jpg";

const NotFound = () => {
  return (
    <>
      <div>
        <img
          src={notFound}
          style={{
            width: "auto",
            height: "600px",
            margin: "0 auto",
            display: "flex",
            marginTop: "20px",
          }}
        />
      </div>
    </>
  );
};

export default NotFound;
