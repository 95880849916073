import VideoAlbumList from "./VideoAlbumList";
import VideoList from "./VideoList";

const VideoAndAlbumList = ({ albumData, isAlbum }) => {
  console.log("AUDIO LIST ", albumData, isAlbum);
  if (isAlbum === true) {
    return <VideoAlbumList albumnData={albumData} />;
  } else {
    return <VideoList videoListData={albumData} isAlbum={isAlbum} />;
  }
};

export default VideoAndAlbumList;
