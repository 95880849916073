import React from "react";
import AudioAlbumList from "./AudioAlbumList";
import AudioListPlayer from "./AudioListPlayer";

const AudioAndAlbumList = ({ albumnData, isAlbum }) => {
  console.log("AUDIO LIST ", albumnData, isAlbum);
  if (isAlbum === true) {
    return <AudioAlbumList albumnData={albumnData} />;
  } else {
    return <AudioListPlayer audioListData={albumnData} />;
  }
};

export default AudioAndAlbumList;
