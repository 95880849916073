import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useNavigate, useHistory } from "react-router-dom";
import { useEffect, useRef } from "react";
import CommonBreadcrumbs from "../../common/CommonBreadcrumbs/CommonBreadcrumbs";
import { fetchAboutusData, fetchSlider } from "../../api/API";
import InnerpageLoader from "../Home/InnerpageLoader";
import "./AboutUs.css";

const AboutUs = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const navigate = useNavigate();

  const handleClick = (items) => {
    navigate(`/about-us/about/${items.details.title}/${items.id}`);
  };

  const handleShortDesc = async (id, name) => {
    navigate(`/about-us/about/${name}/${id}`);
  };
  const handleClickMandir = (id, title) => {
    console.log("CLICKED1 ", id);
    navigate(`/about-us/${title}/${id}`);
  };

  const handleClickYagnshala = () => {
    navigate("/about-us/yagnshala");
  };

  const handleClickGaushala = () => {
    navigate("/about-us/gaushala");
  };

  const handleClickVidhyalaya = () => {
    navigate("/about-us/brahmanand-vidhyalaya");
  };

  const handleClickHostel = () => {
    navigate("/about-us/hostel");
  };

  const handleClickFounder = () => {
    navigate("/about-us/founder");
  };

  const handleClickTradition = () => {
    navigate("/about-us/guru-tradition");
  };

  const breadcrumbsData = [
    { label: "Home", url: "/" },
    <label className="active-link-color">About us</label>,
  ];

  const [showShimmer, setShowShimmer] = useState(true);
  const [aboutUsHistory, setAboutusHistory] = useState([]);
  const [aboutUsTopData, setAboutUsTopData] = useState([]);
  const [aboutUsBottomData, setABoutUsBottomData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [banner, setBanner] = useState(null);
  const [historyImage, setHistoryImage] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetchAboutusData({
          url: apiUrl,
          page: "about us",
        });
        if (response.data.status == true) {
          setAboutusHistory(
            response.data.responseBody.about_us_history.history
          );
          setHistoryImage(response.data.responseBody.about_us_history.image);
          setAboutUsTopData(response.data.responseBody.about_us_top);
          setABoutUsBottomData(response.data.responseBody.about_us_bottom);
          console.log(
            "about_us_bottom",
            response.data.responseBody.about_us_bottom
          );
          console.log("about_us_top", response.data.responseBody.about_us_top);
          console.log(
            "about-us-history",
            response.data.responseBody.about_us_history.history
          );
          console.log("response-about us", response.data.responseBody);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchBanner = async () => {
      setLoading(true);
      try {
        const response = await fetchSlider({
          url: process.env.REACT_APP_API_URL,
          page: "about us",
        });
        setBanner(response.data.responseBody);
        console.log("responsebanner", response.data.responseBody);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchBanner();
  }, []);
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const MAX_DESCRIPTION_LENGTH = 350;

  // const TruncatedDescription = ({ description }) => {
  //   if (description.length > MAX_DESCRIPTION_LENGTH) {
  //     return <>{`${description.substring(0, MAX_DESCRIPTION_LENGTH)}...`}</>;
  //   }
  //   return description;
  // };

  const TruncatedDescription = ({ description }) => {
    if (description.length > MAX_DESCRIPTION_LENGTH) {
      const truncatedText = description.substring(0, MAX_DESCRIPTION_LENGTH);
      const readMoreText = " Read more...";
      return (
        <>
          <span>{truncatedText}</span>
          <span style={{ borderBottom: "1px solid #ccc" }}>{readMoreText}</span>
        </>
      );
    }
    return description;
  };

  function renderShortDescription(shortDescription) {
    if (typeof shortDescription === "string") {
      // If it's a string, display it directly
      return shortDescription;
    } else if (Array.isArray(shortDescription)) {
      // If it's an array, extract and display the names
      return shortDescription?.map((item) => item.name).join(", ");
    } else {
      // Handle other types if necessary
      return ""; // or some default value
    }
  }

  return (
    <>
      <div className="contact-img-wrap">
        <div className="spinner-container-banner">
          <>
            <InnerpageLoader
              src={banner}
              className="about-img"
              onImageLoad={handleImageLoad}
            />
          </>
        </div>
        {imageLoaded && (
          <div className="breadcrumbs-wrap">
            <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
          </div>
        )}
      </div>
      {/* ====================================================================================== */}

      <Container className="pt-5 about-us-page">
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={12} md={8}>
            <div className="container about-container about-us-content">
              <div>
                <span className="span-bg-color">History</span>
              </div>
              <div className="container">
                <div
                  className="container"
                  // style={{ maxHeight: "200px", overflowY: "auto" }}
                >
                  <div>
                    <div className="mt-5">
                      <img
                        alt=""
                        src={historyImage}
                        className="about-hisory-image"
                      />
                    </div>
                    <div>
                      <p
                        dangerouslySetInnerHTML={{ __html: aboutUsHistory }}
                        className="mt-5"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            {!loading &&
              aboutUsTopData?.map((item, index) => (
                <div
                  key={index}
                  onClick={() => handleClick(item)}
                  style={{ cursor: "pointer" }}
                  className={`about-us-content-wrap ${
                    index > 0 ? "second-content" : ""
                  }`}
                >
                  <img
                    src={item.details.image}
                    alt={item.details.title}
                    className="about-us-img"
                  />
                  <div className="about-us-text">
                    <h6 className="mt-3">{item.details.title}</h6>
                    {(() => {
                      try {
                        const shortDescription = JSON.parse(
                          item.details.short_description
                        );

                        if (Array.isArray(shortDescription)) {
                          return (
                            <div>
                              {shortDescription?.map((s, i) => (
                                <p
                                  key={i}
                                  className="about-short-desc"
                                  onClick={() => handleShortDesc(s.id, s.name)}
                                >{`${i + 1}. ${s.name}`}</p>
                              ))}
                            </div>
                          );
                        } else {
                          return (
                            <p
                              className="mt-3"
                              dangerouslySetInnerHTML={{
                                __html: shortDescription,
                              }}
                            ></p>
                          );
                        }
                      } catch (error) {
                        return (
                          <p
                            className="mt-3"
                            dangerouslySetInnerHTML={{
                              __html: item.details.short_description,
                            }}
                          ></p>
                        );
                      }
                    })()}
                  </div>
                </div>
              ))}
          </Grid>
        </Grid>
      </Container>

      {/* ====================================================================================== */}
      <Container>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          className="aboutUs-bottom-img-container"
        >
          {!loading &&
            aboutUsBottomData?.map((item, index) => (
              <Grid
                xs={12}
                sm={12}
                md={4}
                lg={2}
                className="aboutUs-image-wrap"
              >
                <div
                  key={index}
                  className="aboutUs-image-inner-wrap"
                  onClick={() => handleClickMandir(item.id, item.details.title)}
                >
                  <div>
                    <img
                      src={item.details.image}
                      alt={item.details.title}
                      className="aboutus-botttom-images"
                    />
                  </div>
                  <div>
                    <p>{item.details.title}</p>
                  </div>
                </div>
              </Grid>
            ))}
        </Grid>
      </Container>
    </>
  );
};

export default AboutUs;
