import React from "react";
import "./ActivityLoader.css";

const ActivityLoader = () => {
  return (
    <>
      <div className="activity-loader-container">
        <span class="activity-loader"></span>
      </div>
    </>
  );
};

export default ActivityLoader;
