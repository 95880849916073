import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import image1 from "../../assets/images/child_development_activities.jpg";
import image2 from "../../assets/images/community_activities.jpg";
import image3 from "../../assets/images/education_activities.jpg";
import image4 from "../../assets/images/enviormnetal_activities.jpg";
import image5 from "../../assets/images/medical_activities.jpg";
import image6 from "../../assets/images/cultural_activities.jpg";
import SectionTitle from "../../common/SectionTitle/SectionTitle";
import { GrFormNextLink } from "react-icons/gr";
import AOS from "aos";
import "aos/dist/aos.css";
import { fetchActivitiy } from "../../api/API";
import Loader from "../../common/Loader/Loader";
import "./Activities.css";
import { TablePagination } from "@mui/material";

const Activities = ({ setIsActivityLoaded }) => {
  const navigate = useNavigate();

  // const handleClick = (activity_id) => {
  //   // navigate("/activities");
  //   navigate(`/activities/${activity_id}`);
  // };

  const handleClick = (activity_id, title) => {
    navigate(`/activities/${activity_id}/${title}`);
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  const [apiData, setApiData] = useState({});
  const [loading, setLoading] = useState(false);

  console.log("apiData", apiData);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetchActivitiy({
        url: process.env.REACT_APP_API_URL,
        page: "home",
      });
      if (response.data.status == true) {
        setIsActivityLoaded(true);
        setApiData(response.data.responseBody.activities);
        console.log("Homeactivity", response.data.responseBody.activities);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("apiData", apiData);
  }, [apiData]);

  return (
    <>
      <div className="section-padding"></div>
      <Container>
        <div className="spinner-container">
          {loading && (
            <div className="spinner">
              {/* <img src={spinner} alt="Spinner" /> */}
              <Loader />
            </div>
          )}
          <>
            <SectionTitle title="Activities" />
            <div className="section-padding"></div>
            <Grid container spacing={3} justifyContent="center">
              {Object.values(apiData).map((activity) => (
                <Grid item xs={12} sm={4} md={3} key={activity.id}>
                  <Card
                    className="activities-content-wrap"
                    data-aos="zoom-in"
                    onClick={() =>
                      handleClick(activity.activity_id, activity.title)
                    }
                  >
                    <img
                      src={activity.image}
                      alt={activity.title}
                      className="activities-img"
                    />
                    <div className="activities-content">
                      <h4>{activity.name}</h4>
                      <GrFormNextLink size={20} />
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        </div>
      </Container>
    </>
  );
};

export default Activities;
