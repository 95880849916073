import React, { useState, useEffect } from "react";
import image from "../../assets/images/subheader.jpg";
import image1 from "../../assets/images/swaminarayan.jpg";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import SectionTitle from "../../common/SectionTitle/SectionTitle";
import { useNavigate } from "react-router-dom";
import CommonBreadcrumbs from "../../common/CommonBreadcrumbs/CommonBreadcrumbs";
import Typography from "@mui/material/Typography";
import { MdSquare } from "react-icons/md";
import { useParams } from "react-router-dom";
import { fetchAboutusDetails } from "../../api/API";
import Aboustype1 from "./Aboustype1/Aboustype1";
import Aboustype2 from "./Aboustype2/Aboustype2";
import Aboustype3 from "./Aboustype3/Aboustype3";
import "./About.css";

const About = () => {
  const breadcrumbsData = [
    { label: "Home", url: "/" },
    { label: "About Us", url: "/about-us" },
    <label color="text.primary" className="active-link-color">
      {/* Lord Swaminarayan */}
    </label>,
  ];

  const [aboutUsData, setAboutUsData] = useState([]);
  const [aboutUsType, setAboutUsType] = useState([]);
  const [loading, setLoading] = useState(false);

  const params = useParams();
  console.log("aboutId", params.id);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetchAboutusDetails({
          url: apiUrl,
          page: "about us",
          id: params.id,
        });
        setAboutUsData(response.data.responseBody);
        setAboutUsType(
          response.data.responseBody.about_us_data.select_type_about_us
        );
        console.log(
          "about-us-type",
          response.data.responseBody.about_us_data.select_type_about_us
        );
        console.log("response", response.data.responseBody.about_us_data);
        console.log("aboutUsData", response.data.responseBody);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : aboutUsType === "1" ? (
        <Aboustype1 aboutUsData={aboutUsData} />
      ) : aboutUsType === "2" ? (
        <Aboustype2 aboutUsData={aboutUsData} />
      ) : aboutUsType === "3" ? (
        <Aboustype3 aboutUsData={aboutUsData} />
      ) : (
        <p>No matching found for the provided aboutUsType</p>
      )}
    </>
  );
};
export default About;
